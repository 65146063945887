<template>
  <div id="home" class="pb-5">
    <home-loader v-if="loader"/>
    <div v-else>
      <section id="section1" class="bg-light-blue">
        <div class="container">
          <div class="row">
            <div class="col-md-7 order-2 order-md-1 py-md-3 py-2">
              <div class="d-none d-md-block">
                <span>Find The Right Deal</span>
                <h1>We’re free and trusted by 15 million customers in the UK</h1>
                <ul class="mt-4 pl-0 header-bullets">
                  <li class="d-flex align-items-center rounded-pill b-blue mb-1">
                    <div>
                      <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-blue">
                        <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                          <span class="fs-13"> 1 </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 pl-2">
                        Find your next Phone, Broadband, Energy Provider, Network and contract
                      </p>
                    </div>
                  </li>
                  <li class="d-flex align-items-center rounded-pill b-yellow mb-1">
                    <div>
                      <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-yellow">
                        <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                          <span class="fs-13"> 2 </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 pl-2">
                        Get help finding the very best deals from the top network providers
                      </p>
                    </div>
                  </li>
                  <li class="d-flex align-items-center rounded-pill b-dark-blue mb-1">
                    <div>
                      <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-dark-blue">
                        <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                          <span class="fs-13"> 3 </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 pl-2">
                        Compare ALL UK’s TOP Providers - Compare the entire market in seconds so you can find the perfect deal for you.
                      </p>
                    </div>
                  </li>
                  <li class="d-flex align-items-center rounded-pill b-blue mb-1">
                    <div>
                      <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-blue">
                        <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                          <span class="fs-13"> 4 </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 pl-2">
                        Save Money - Find the best price for the mobile, Network, Energy, Broadband and Contract you want in seconds across all the UK’s Top Providers.Get the very best deals on the market.
                      </p>
                    </div>
                  </li>
                  <li class="d-flex align-items-center rounded-pill b-yellow">
                    <div>
                      <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-yellow">
                        <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                          <span class="fs-13"> 5 </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 pl-2">
                        Free To Use Service - switchuk.com is a completely free to use service designed to help consumers get what they want.
                      </p>
                    </div>
                  </li>
                </ul>
                <deals-btn></deals-btn>
              </div>
            </div>
            <div class="col-md-5 order-1 order-md-2 text-center d-flex align-items-end">
              <div class="d-block d-md-none">
                <span>Find The Right Deal</span>
                <h2>
                  No one makes it simpler than SwitchUK
                </h2>
                <div class="text-left">
                  <ul class="mt-4 pl-0 header-bullets">
                    <li class="d-flex align-items-center rounded-pill b-blue">
                      <div>
                        <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-blue">
                          <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                            <span class="fs-13"> 1 </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p class="mb-0 pl-2">
                          Find your next phone, network and contract
                        </p>
                      </div>
                    </li>
                    <li class="d-flex align-items-center rounded-pill b-yellow">
                      <div>
                        <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-yellow">
                          <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                            <span class="fs-13"> 2 </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p class="mb-0 pl-2">
                          Get help finding the very best deals from the top network providers
                        </p>
                      </div>
                    </li>
                    <li class="d-flex align-items-center rounded-pill b-dark-blue">
                      <div>
                        <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-dark-blue">
                          <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                            <span class="fs-13"> 3 </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p class="mb-0 pl-2">
                          Compare ALL UK Network Providers - Compare the entire market in seconds so you can find the perfect mobile for you.
                        </p>
                      </div>
                    </li>
                    <li class="d-flex align-items-center rounded-pill b-blue">
                      <div>
                        <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-blue">
                          <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                            <span class="fs-13"> 4 </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p class="mb-0 pl-2">
                          Save Money - Find the best price for the mobile you want in seconds across all the networks.Get the very best deals on the market.
                        </p>
                      </div>
                    </li>
                    <li class="d-flex align-items-center rounded-pill b-yellow">
                      <div>
                        <div class="d-flex align-items-center rounded-circle justify-content-center bullet-circle-yellow">
                          <div class="d-flex align-items-center rounded-circle justify-content-center inner-circle">
                            <span class="fs-13"> 5 </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p class="mb-0 pl-2">
                          Free To Use Service - switchuk.com is a completely free to use service designed to help consumers get what they want.
                        </p>
                      </div>
                    </li>
                  </ul>
                  <deals-btn></deals-btn>
                </div>
              </div>
              <div class="d-none d-md-block">
                <img :src="require('images/boy.png')" width="480px">
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section2" class="back-color">
        <div class="text-center py-2">
          <img
            class="m-2"
            :src="require('images/logos/largelogo/vodafone.png')"
            height="35"
          >
          <img
            class="m-2"
            :src="require('images/logos/largelogo/virginmobile.png')"
            height="35"
          >
          <img
            class="m-2"
            :src="require('images/logos/o2.png')"
            height="35">
          <img
            class="m-2"
            :src="require('images/logos/giffgaff.png')"
            height="35"
          >
          <img
            class="m-2"
            :src="require('images/logos/largelogo/ee.png')"
            height="35">
          <img
            class="m-2"
            :src="require('images/logos/3.png')"
            height="35">
          <img
            class="m-2"
            :src="require('images/logos/smarty.png')"
            height="35"
          >
          <img
            class="m-2"
            :src="require('images/logos/id.png')"
            height="35"
          >
          <img
            class="m-2"
            :src="require('images/logos/voxi.png')"
            height="35">
          <img
            class="m-2"
            :src="require('images/logos/bt.png')"
            height="35">
          <img
            class="m-2"
            :src="require('images/logos/sky.png')"
            height="35">
          <img
            class="m-2"
            :src="require('images/logos/talkmobile.png')"
            height="35">
        </div>
      </section>
      <section id="section4">
        <div class="container pt-4">
          <div class="">
            <h2 class="text-center">
              <span>Why Choose SwitchUk</span>
            </h2>
          </div>
          <div class="row pt-4 mx-0">
            <div class="col-12">
              <div>
                <div class="row mx-0">
                  <div id="how-to-2" class="col-md-6 text-center">
                    <img class="mr-2 rounded-circle obj-fit" :src="require('images/easy_switching.png')" width="200">
                    <h3 class="text-blue mt-2 mb-0">
                      <span>
                        Easy Switching
                      </span>
                    </h3>
                    <p>
                      Hassle Free Process. Compare deals in 10 seconds & apply to switch right away!
                    </p>
                  </div>
                  <div id="how-to-2" class="col-md-6 text-center">
                    <img class="mr-2 rounded-circle obj-fit" :src="require('images/expert_advise.jpg')" width="200" height="162">
                    <h3 class="text-blue mt-2 mb-0">
                      <span>
                        Expert Advice
                      </span>
                    </h3>
                    <p>
                      Our team of energy experts are ready to provide you with the best advice.
                    </p>
                  </div>
                  <div id="how-to-1" class="col-md-6 text-center pt-4">
                    <img class="mr-2 rounded-circle obj-fit" :src="require('images/exclusive_deals.png')" width="200">
                    <h3 class="text-blue mt-2 mb-0 text-capitalize">Exclusive Deals</h3>
                    <p>
                     We have hundreds of exclusive Mobile, Network, Broadband, Energy deals for your area. You can check in just 10 seconds using just your postcode, with no detailed documentation required*.
                    </p>
                  </div>
                  <div id="how-to-3" class="col-md-6 text-center pt-4">
                    <img class="mr-2 rounded-circle obj-fit" :src="require('images/top_deal_providers.png')" width="200" height="162">
                    <h3 class="text-blue mt-2 mb-0">
                      <span>
                        Top Rated Deal Providers in UK
                      </span>
                    </h3>
                    <p>
                      We are trusted by 15 million customers across the UK to provide the best deals. We help you save big and save for your future.
                    </p>
                  </div>
                </div>
                <div class="divider mt-3 mb-4"></div>
                <div id="faqs">
                  <h2 class="mb-3">
                    <p class="text-center mb-0">
                      Switch Your Current Deals & Save Up To 30%
                    </p>
                    <p class="text-center">
                      Compare Your Current Deal & Get Guaranteed Savings
                    </p>
                  </h2>
                  <div>
                    <h3 class="text-blue mb-3">
                      <span>
                        Mobile
                      </span>
                    </h3>
                    <p>
                      Finding it hard to choose the best mobile deal? Compare all your networks in the UK with one GO! <a href="/compare_deals">Compare Mobile Deals</a>
                    </p>
                  </div>
                  <div class="divider mt-3 mb-4"></div>
                  <div>
                    <h3 class="text-blue mb-3">
                      <span>
                        Energy
                      </span>
                    </h3>
                    <p>
                      350+ discounted energy deals in your neighborhood. Why Wait? Compare & Switch to the best deals Right Now! <a href="https://energy.switchuk.com/">Compare Energy</a>
                    </p>
                  </div>
                  <div class="divider mt-3 mb-4"></div>
                  <div>
                    <h3 class="text-blue mb-3">
                      <span>
                        Sim-Only
                      </span>
                    </h3>
                    <p>
                      What if i say? You can buy a SIM comparing different networks and choosing the best suitable package at one place? It is possible! View our deals to know more <a href="https://switchuk.com/sim_only_deals">Compare Sim-only Deals</a>
                    </p>
                  </div>
                  <div class="divider mt-3 mb-4"></div>
                  <div>
                    <h3 class="text-blue mb-3">
                      <span>
                        Broadband
                      </span>
                    </h3>
                    <p>
                      Compare cheap broadband deals at affordable prices with speed check. <a href="http://broadband.switchuk.com/">Compare Broadband Deals</a>
                    </p>
                  </div>
                  <div class="divider mt-3 mb-4"></div>
                  <div>
                    <h2 class="mb-3">
                      <p class="text-center mb-0">
                        How to Switch with SwitchUK
                      </p>
                    </h2>
                  </div>
                  <div>
                    <h3 class="text-blue mb-3">
                      <span>
                        Enter Your Details
                      </span>
                    </h3>
                    <p>
                      Enter Your Postcode
                    </p>
                  </div>
                  <div class="divider mt-3 mb-4"></div>
                  <div>
                    <h3 class="text-blue mb-3">
                      <span>
                       Pick Your Deal
                      </span>
                    </h3>
                    <p>
                      Compare deals in 10 Seconds with your postcode and browse the discounts.
                    </p>
                  </div>
                  <div class="divider mt-3 mb-4"></div>
                  <div>
                    <h3 class="text-blue mb-3">
                      <span>
                        We Do All The Work
                      </span>
                    </h3>
                    <p>
                      Our expert team will contact you to answer all your questions and inform everyone in the chain.
                    </p>
                  </div>
                  <div class="divider mt-3 mb-4"></div>
                  <div>
                    <h3 class="text-blue mb-3">
                      <span>
                        Relax & Spend your savings
                      </span>
                    </h3>
                    <p>
                      We help you save up to 30% on your current deal, so you save money and time to spend with your family.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
  import {mapMutations, mapGetters} from 'vuex';
  import Dom from 'dom/manipulator.js'
  import _ from "underscore"
  import DealsBtn from './components/deals_btn'
  import WhyUs from './components/why_us'
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faPlug, faQuoteRight, faStar, faAngleRight, faCaretRight } from '@fortawesome/free-solid-svg-icons'
  import { VueAgile } from 'vue-agile'
  import http from '../common/http'
  import HomeLoader from '../common/home_page_loader'
  library.add(faPlug, faQuoteRight, faStar, faAngleRight, faCaretRight)
  export default {
    name: 'Home',
    data: function() {
      return {
        bestOptions: [
          {
            icon: 'plug',
            heading: 'Compare ALL UK Network Providers',
            data: 'Compare the entire market in seconds so you can find the perfect mobile for you.',
          },
          {
            icon: 'quote-right',
            heading: 'Save Money',
            data: 'Find the best price for the mobile you want in seconds across all the networks.Get the very best deals on the market.',
          },
          {
            icon: 'star',
            heading: 'Free To Use Service',
            data: 'switchuk.com is a completely free to use service designed to help consumers get what they want.',
          },
        ],
        topProducts: [],
      }
    },
    components: {
      DealsBtn,
      WhyUs,
      VueAgile,
      HomeLoader
    },
    created(){
      this.setSpinner(false),
      this.fetchTopProducts()
    },
    computed: {
      ...mapGetters([
        'loader'
        ]),
    },
    methods:{
      ...mapMutations([
        'setSpinner',
        'setLoader'
      ]),
      setActiveHowTo: (event) => {
        let icons = document.querySelectorAll('.nav-link > .fa-angle-right')
        _.each( icons , icon => {
          Dom.addClass(icon, 'd-none')
        })
        let link = event.currentTarget.querySelector('.fa-angle-right')
        Dom.removeClass(link, 'd-none')
      },
      fetchTopProducts(){
      http
        .get(`/top_products.json`)
        .then((res) => {
          this.topProducts = res.data
          this.setLoader(false)
        })
        .catch((err) => {
          console.log(err);
        });
      },
    }
  }
</script>
<style type="text/css" lang="scss" scoped>
  .sticky-top {
    top: 30px;
  }
  ul > li {
    list-style: unset;
  }

  .fa-angle-right {
    top: 10px;
    left: 0px;
  }

  #section4 img.logo {
    object-fit: contain !important;
  }

  #section4 .sidebar {
    background-image: linear-gradient( to bottom, #273238, #0469b2);
  }
  .back-color{
    background-color:#ffc107;
  }
  .bullet-circle-blue{
    height: 34px;
    width: 34px;
    border: 3px solid #5ca7de !important;
    border-radius: 50px;
    .inner-circle{
      height: 20px;
      width: 20px;
      border: 1px solid #5ca7de !important;
      border-radius: 50px;
    }
  }
  .bullet-circle-yellow{
    height: 34px;
    width: 34px;
    border: 3px solid #ffc107 !important;
    border-radius: 50px;
    .inner-circle{
      height: 20px;
      width: 20px;
      border: 1px solid #ffc107 !important;
      border-radius: 50px;
    }
  }
  .bullet-circle-dark-blue{
    height: 34px;
    width: 34px;
    border: 3px solid #005593 !important;
    border-radius: 50px;
    .inner-circle{
      height: 20px;
      width: 20px;
      border: 1px solid #005593 !important;
      border-radius: 50px;
    }
  }
  .header-bullets{
    ul{
      list-style: none;
    }
  }
  .b-blue{
    background: #5ca7de0f;
  }
  .b-yellow{
    background: #ffd1461c;
  }
  .b-dark-blue{
    background: #00559312;
  }
</style>
