<template>
  <div class="row w-100 mt-3 ml-0">
    <div class="deal-btn col-lg-6 col-xl-5 pr-0 mr-2 pl-0" @click="redirect_to(`${deal.route}`)" v-for="deal in this.dealBtns">
      <div class="px-3 py-3 cursor-p bg-white d-flex align-items-center">
        <img class="mr-2" :src="deal.img" width="50">
        <span class="h4 align-middle mb-0">
          {{ deal.title }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'DealsButton',
    data: function() {
      return {
        dealBtns: [
          { route: '/sim_only_deals', title: 'Sim deals', img: require('images/svg/sim.svg') },
          { route: '/mobiles', title: 'Mobile deals', img: require('images/svg/phone.svg') },
          { route: '/broadband', title: 'Broadband deals', img: require('images/svg/broadband.png') },
          { route: '/energy', title: 'Energy deals', img: require('images/svg/energy.png') },
           //{ route: '/loans', title: 'Loan deals', img: require('images/svg/loan.svg') }, 

        ]
      }
    },
    methods: {
      redirect_to(route) {
        var params = window.location.search
        switch(route) {
          //  case '/loans':
          //   window.location = `https://switchuk.monevo.co.uk/`
            // break;
          case '/sim_only_deals':
            window.location = `https://mobile-phones.switchuk.com/${params}`
            break;
          case '/mobiles':
            window.location = `https://mobile-phones.switchuk.com/${params}`
            break;
          case '/broadband':
            window.location = `http://broadband.switchuk.com/${params}`
            break;
          case '/energy':
            window.location = `https://energy.switchuk.com/${params}`
            break;
          default:
            window.location = 'https://switchuk.com/'
        }
      }
    },
  }
</script>
<style type="text/css">
  .deal-btn {
    border: 2px solid transparent;
  }
  .deal-btn:hover{
    border: 2px solid #000;
  }
</style>
