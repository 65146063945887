<template>
  <div>
    <main-header></main-header>
    <div id="body-wrapper">
        <router-view :key="viewKey"></router-view>
    </div>
    <main-footer></main-footer>
  </div>
</template>
<script>
  import MainHeader from '../common/header'
  import Packages from './packages'
  import MainFooter from '../common/footer'
  export default {
    name: 'App',
    components: {
      Packages,
      MainHeader,
      MainFooter
    },
    data() {
      return{
        viewKey: 1
      }
    },
    methods: {
      updateViewKey() {
        this.viewKey+=1;
      }
    }
  }

</script>

<style type="text/css" scoped>
  #body-wrapper {
    min-height: calc(100vh - 283px);
  }
</style>
